import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Popover, Transition } from "@headlessui/react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { faChild } from "@fortawesome/free-solid-svg-icons/faChild"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope"
import { faHandshake } from "@fortawesome/free-solid-svg-icons/faHandshake"
import { faPenSquare } from "@fortawesome/free-solid-svg-icons/faPenSquare"
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie"
import { StaticImage } from "gatsby-plugin-image"

const navigation = [
	{
		name: "Bewerbergewinnung",
		icon: <FontAwesomeIcon className="mr-1" icon={faChild} />,
		special: 1,
		path: "#",
		sublinks: [
			{ name: "Karriere Website", path: "/karriere-website-kosten-angebot" },
			{
				name: "E-Recruiting-Analyse",
				path: "/e-recruiting-analyse-kosten-sparen",
			},
			{ name: "Active Sourcing", path: "/active-sourcing-konzept-zum-erfolg" },
		],
	},
	{
		name: "Recruiting Beratung",
		icon: <FontAwesomeIcon className="mr-1" icon={faHandshake} />,
		special: 2,
		path: "#",
		sublinks: [
			{ name: "Recruiting Strategie", path: "/recruiting-strategie-kmu" },
			{ name: "Recruiting Impulse", path: "/recruiting-impulse-neue-ideen" },
			{
				name: "Recruiting Seminare",
				path: "/recruiting-seminare-fuer-recruiter",
			},
		],
	},
	{
		name: "Recruiting in Aktion",
		icon: <FontAwesomeIcon className="mr-1" icon={faUserTie} />,
		special: 3,
		path: "#",
		sublinks: [
			{
				name: "Recruiting KPI´s & Motivation",
				path: "/recruiting-kpi-motivation",
			},
			{
				name: "Recruitervermittlung",
				path: "/recruiter-vermittlung-festanstellung",
			},
			{ name: "Recruiter mieten", path: "/recruiter-mieten-und-rpo-laeuft" },
		],
	},
	{ name: "Termin buchen", path: "/termin" },
	{
		name: "Wir",
		path: "/erfolgreiches-recruiting-ermoeglichen-wir/",
	},
]

const Header = function () {
	const [menuHover, setMenuHover] = useState()

	return (
		<header className="sticky top-0 z-20 shadow">
			<div className="bg-white w-full hidden md:block">
				<div className="sectionwidth text-sm mx-auto flex justify-end w-full">
					<div className="flex items-center ml-2 text-gray-500 font-medium hover:text-gray-600">
						<FontAwesomeIcon icon={faPenSquare} className="mr-1" />
						<Link to="/kontakt">Kontakt</Link>
					</div>
					<a
						href="mailto:hallo@internes-recruiting.de"
						className="flex items-center ml-2 text-gray-500 font-medium hover:text-gray-600"
					>
						<FontAwesomeIcon icon={faEnvelope} className="mr-1" />
						hallo@internes-recruiting.de
					</a>
				</div>
			</div>
			<Popover className="w-full bg-white min-h-[96px]">
				{({ open }) => (
					<nav className="sectionwidth mx-auto" aria-label="Top">
						<div className="w-full pb-2 pt-2 lg:pt-0 flex items-center justify-between">
							<Link to="/">
								<span className="sr-only">Interne Jobs</span>
								<StaticImage
									width={300}
									src="../images/logo.png"
									alt=""
									placeholder=""
									loading="eager"
								/>
							</Link>
							<div className="hidden space-x-8 lg:block">
								{navigation.map((link, index) => {
									return (
										<div
											key={index}
											className={
												"inline-block text-base font-bold relative " +
												(link.special
													? (link.special === 1 &&
															"bg-primary border-2 border-secondary border-r-0 rounded-l-lg text-white py-1 px-2 !-mr-8") ||
													  (link.special === 2 &&
															"bg-secondary-800 border-2 border-secondary border-r-0 border-l-0 text-white py-1 px-2 !-mr-8") ||
													  (link.special === 3 &&
															"bg-primary-900 border-2 border-secondary border-l-0 rounded-r-lg text-white py-1 px-2")
													: "text-gray-600 hover:text-secondary")
											}
											onMouseEnter={() => setMenuHover(index)}
											onMouseLeave={() => setMenuHover(false)}
											role="menu"
										>
											{link.anchor ? (
												<AnchorLink
													to={link.path}
													partiallyActive={true}
													activeClassName="text-secondary"
													stripHash={true}
												>
													{link.icon}
													{link.name}
													{link.sublinks && (
														<FontAwesomeIcon
															icon={faChevronDown}
															className="ml-1"
														/>
													)}
												</AnchorLink>
											) : (
												<Link
													to={link.path}
													partiallyActive={true}
													activeClassName="text-secondary"
												>
													{link.icon}
													{link.name}
													{link.sublinks && (
														<FontAwesomeIcon
															icon={faChevronDown}
															className="ml-1"
														/>
													)}
												</Link>
											)}
											{link.sublinks && (
												<Transition
													show={menuHover === index}
													enter="transition transform ease-out duration-200"
													enterFrom="opacity-0 -translate-x-4"
													enterTo="opacity-100 translate-x-0"
													leave="transition transform ease-in duration-150"
													leaveFrom="opacity-100 translate-x-0"
													leaveTo="opacity-0 translate-x-4"
													className={
														"absolute left-0 flex flex-col items-center justify-center rounded ring-1 ring-black ring-opacity-5 " +
														(link.special
															? (link.special === 1 &&
																	"bg-primary text-white p-4 space-y-2") ||
															  (link.special === 2 &&
																	"bg-secondary-800 text-white p-4 space-y-2") ||
															  (link.special === 3 &&
																	"bg-primary-900 text-white p-4 space-y-2")
															: "bg-white border-t-4 border-primary text-gray-600 px-2 py-1 divide-y-2 shadow-lg")
													}
												>
													{link.sublinks.map((sublink, index) => {
														return (
															<div
																key={index}
																className={
																	"block text-sm font-bold relative whitespace-nowrap w-full text-left py-1 hover:text-secondary"
																}
															>
																{sublink.anchor ? (
																	<AnchorLink
																		to={sublink.path}
																		activeClassName="text-secondary"
																		stripHash={true}
																	>
																		{sublink.name}
																	</AnchorLink>
																) : (
																	<Link
																		to={sublink.path}
																		partiallyActive={true}
																		activeClassName="text-secondary"
																	>
																		{sublink.name}
																	</Link>
																)}
															</div>
														)
													})}
												</Transition>
											)}
										</div>
									)
								})}
							</div>

							<div className="-mr-2 -my-2 lg:hidden">
								<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
									<span className="sr-only">Open menu</span>
									<FontAwesomeIcon icon={faBars} size="2x" />
								</Popover.Button>
							</div>
						</div>

						<Transition
							show={open}
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Popover.Panel
								static
								className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right max-h-screen overflow-y-auto lg:hidden"
							>
								<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
									<div className="pt-5 pb-6 px-5">
										<div className="flex items-center justify-between">
											<Link to="/">
												<span className="sr-only">Interne Jobs</span>
												<StaticImage
													className="w-auto h-auto"
													width={300}
													src="../images/logo.png"
													alt=""
													placeholder=""
													loading="lazy"
												/>
											</Link>
											<div className="-mr-2">
												<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
													<span className="sr-only">Close menu</span>
													<FontAwesomeIcon icon={faTimes} size="lg" />
												</Popover.Button>
											</div>
										</div>
										<div className="flex flex-col gap-4 mt-2">
											{navigation.map((link, index) => (
												<div
													key={index}
													className={
														"inline-block text-base font-medium " +
														(link.special
															? (link.special === 1 &&
																	"bg-primary p-2 text-white -mb-4") ||
															  (link.special === 2 &&
																	"bg-secondary-800 p-2 text-white -mb-4") ||
															  (link.special === 3 &&
																	"bg-primary-900 p-2 text-white")
															: "bg-white text-gray-600")
													}
												>
													{link.anchor ? (
														<AnchorLink
															to={link.path}
															activeClassName="text-secondary"
															className="hover:text-secondary"
															stripHash={true}
														>
															{link.icon}
															{link.name}
														</AnchorLink>
													) : (
														<Link
															to={link.path}
															partiallyActive={true}
															className="hover:text-secondary"
															activeClassName="text-secondary"
														>
															{link.icon}
															{link.name}
														</Link>
													)}
													{link.sublinks &&
														link.sublinks.map((sublink, index) => (
															<div
																key={index}
																className="pl-4 mt-2 block text-base font-medium"
															>
																{sublink.anchor ? (
																	<AnchorLink
																		to={sublink.path}
																		className="hover:text-secondary"
																		activeClassName="text-secondary"
																		stripHash={true}
																	>
																		{sublink.name}
																	</AnchorLink>
																) : (
																	<Link
																		to={sublink.path}
																		partiallyActive={true}
																		className="hover:text-secondary"
																		activeClassName="text-secondary"
																	>
																		{sublink.name}
																	</Link>
																)}
															</div>
														))}
												</div>
											))}
										</div>
										<div class="grid grid-cols-1 gap-2 text-sm mt-6">
											<Link
												to="/kontakt"
												className="flex items-center text-gray-500 font-medium hover:text-secondary"
											>
												<FontAwesomeIcon icon={faPenSquare} className="mr-2" />
												Kontakt
											</Link>
											<a
												href="mailto:hallo@internes-recruiting.de"
												className="flex items-center text-gray-500 font-medium hover:text-secondary"
											>
												<FontAwesomeIcon icon={faEnvelope} className="mr-2" />
												hallo@internes-recruiting.de
											</a>
										</div>
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</nav>
				)}
			</Popover>
		</header>
	)
}

export default Header
