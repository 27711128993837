import React, { Fragment, useRef } from "react"
import { useCookies } from "react-cookie"
import { Dialog, Transition } from "@headlessui/react"

import Header from "./header"
import Footer from "./footer"

// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css"

const isBrowser = typeof window !== "undefined"

const Layout = (props) => {
	let [cookies, setCookies] = useCookies(["cookie_accepted"])

	let completeButtonRef = useRef(null)

	function cookieAccept() {
		setCookies("cookie_accepted", true, {
			path: "/",
			expires: new Date("2099-01-01T06:30:00.000Z"),
		})
	}

	return (
		<div>
			<Transition
				appear
				show={!cookies.cookie_accepted && isBrowser && navigator?.cookieEnabled}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-50 overflow-y-auto"
					onClose={() => {}}
					static={true}
					initialFocus={completeButtonRef}
				>
					<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<Dialog.Title
									as="h3"
									className="text-lg font-medium leading-6 text-gray-900"
								>
									Cookie Hinweis
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-sm text-gray-500">
										Um unsere Webseite für Sie optimal zu gestalten und
										fortlaufend verbessern zu können, verwenden wir Cookies.
										Durch die weitere Nutzung der Webseite stimmen Sie der
										Verwendung von Cookies zu. Weitere Informationen zu Cookies
										erhalten Sie in unserer{" "}
										<a
											className="text-primary hover:underline"
											href="/datenschutz"
										>
											Datenschutzerklärung
										</a>
										.
									</p>
								</div>

								<div className="mt-4">
									<button
										type="button"
										className="btn btn-primary"
										onClick={cookieAccept}
										ref={completeButtonRef}
									>
										Verstanden
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
			<Header />
			<main
				style={{
					//same effect as overflow hidden, but doesnt prevent stickys
					display: "grid",
					gridTemplateColumns: "1fr",
					justifyItems: "center",
				}}
			>
				{props.children}
			</main>
			<Footer />
		</div>
	)
}

export default Layout
